// external imports
import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled, { css } from 'styled-components'
import HtmlToReact, { Parser as HtmlToReactParser } from 'html-to-react'

// helpers
import { up, down, addResponsivity } from '../lib/styles'
import { extractNodes, extractNodesFromData, extractNodesFromDataApplyLangs } from '../lib/helpers'
import { applyLang, applyLangSingle } from '../lib/intl'
import injectIntl from '../lib/intl/injectIntl'
import { enhanceContent } from '../lib/post'

// components
import T from '../lib/intl/T'
import { SEO, Title, Text, Gap, Row, Col, RoundedImage, Link, HexaImage } from '../components'

import listItemMarkerSrc from '../data/images/list-item-marker.svg'
import { useContentImages } from '../hooks/useContentImages'

const Content = styled.div`
  ${({ theme: { colors, fonts } }) => css`
    & > .p {
      margin-bottom: 24px;
    }
    /* // TODO - toto by možná bylo dobré mít i na blogu */
    li {
      color: ${colors.text};
      font-family: ${fonts.muli};
      font-size: 16px;
      font-weight: 400;
      line-height: 25px;
      padding-left: 6px;
    }
    ul {
      list-style-image: url(${listItemMarkerSrc});
      padding-left: 16px;
      margin-top: 0;
      margin-bottom: 24px;
    }
    & > h2 {
      color: ${colors.blue};
      font-family: ${fonts.signika};
      font-size: 22px;
      font-weight: 600;
      line-height: 30px;
      margin: 0;
      padding: 0;
      margin-bottom: 8px;
    }
  `}
`

function JobOfferTemplate(props) {
  const {
    intl: { language, t },
    location: { pathname },
    data,
  } = props

  const { page } = extractNodesFromDataApplyLangs(data, language)

  const contentImages = useContentImages()

  const contentReactElement = enhanceContent({
    content: page.content,
    contentImages,
  })

  return (
    <>
      <SEO title={`${page.title}`} pathname={pathname} description={page.metaDesc} />
      <Gap.Top />
      <Col maxWidth="760px" width="100%" alignSelf="center" alignItems="flex-start">
        <Title.BiggerTitle innerAs="h1">{page.title}</Title.BiggerTitle>
        <Gap gap="36px" mobileGap="24px" />

        <Text responsive maxWidth="650px">
          <Content>{contentReactElement}</Content>
        </Text>
        <Gap gap="80px" mobileGap="80px" />
      </Col>
    </>
  )
}

export default injectIntl(JobOfferTemplate)

export const pageQuery = graphql`
  query ($id: String!) {
    page: pages(id: { eq: $id }) {
      ...PageFragment
    }
    # contentImages: allFile(filter: { relativePath: { regex: "/^uploaded-image-/" } }) {
    #   edges {
    #     node {
    #       ...PostContentImage
    #       relativePath
    #     }
    #   }
    # }
  }
`
